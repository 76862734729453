const getDefaultState = () => {
    return {
        tour: [],
        step: [],
        allUserTours: [],
        availableTours: [],
        tourVisitorInterests: [],
        allModelTours: [],
        userCurrentTour: null
    };
};
const state = getDefaultState();

const getters = {
    getTour: state => id => {
        return state.step.find(tour => tour.id === id);
    },
    getStep: state => id => {
        return state.tour.find(step => step.id === id);
    },
    getTourVisitorInterests: state => (visitorIds, tourId) => {
        const relevantInterests = [];

        for (let i = 0; i < state.tourVisitorInterests.length; i++) {
            for (let b = 0; b < visitorIds.length; b++) {
                if (
                    visitorIds[b] === state.tourVisitorInterests[i].userId &&
                    tourId === state.tourVisitorInterests[i].tourId
                ) {
                    relevantInterests.push(state.tourVisitorInterests[i]);
                }
            }
        }

        return relevantInterests;
    },
    allUserTours: state => state.allUserTours,
    availableTours: state => state.availableTours
};

const mutations = {
    setTour(state, cities) {
        state.tour.push(cities);
    },
    setStep(state, step) {
        state.step.push(step);
    },
    setAllUserTours(state, tours) {
        state.allUserTours = tours;
    },
    setAvailableTours(state, tours) {
        state.availableTours = tours;
    },
    updateAllUserTours(state, updateInfo) {
        if (state.allUserTours.length) {
            const tourIndex = state.allUserTours.findIndex(tour => tour.id === updateInfo.tourId);
            if (tourIndex !== -1) {
                if (!state.allUserTours[tourIndex].feedbacks) {
                    state.allUserTours[tourIndex].feedbacks = {};
                }
                state.allUserTours[tourIndex].feedbacks[updateInfo.storeId] = { normal: "0" };
                const feedbacks = state.allUserTours[tourIndex].feedbacks[updateInfo.storeId];

				const number = Number(feedbacks.normal) + 1;
				state.allUserTours[tourIndex].feedbacks[updateInfo.storeId].normal = number.toString();
            }
        }

        if (state.availableTours.length) {
            const tourIndex = state.availableTours.findIndex(tour => tour.id === updateInfo.tourId);
            if (tourIndex !== -1) {
                if (!state.availableTours[tourIndex].feedbacks) {
                    state.availableTours[tourIndex].feedbacks= {};
                }
                state.availableTours[tourIndex].feedbacks[updateInfo.storeId] = { normal: "0" };
                const feedbacks = state.availableTours[tourIndex].feedbacks[updateInfo.storeId];

				const number = Number(feedbacks.normal) + 1;
				state.availableTours[tourIndex].feedbacks[updateInfo.storeId].normal = number.toString();
            }
        }
    },
    addHomepageGuideTour(state, tour) {
        state.availableTours.push(tour);
    },
    addUserGuideTour(state, tour) {
        state.allUserTours.push(tour);
    },
    addTourVisitorInterests(state, interests) {
        let visitorInterests;

        for (let i = 0; i < interests.length; i++) {
            visitorInterests = {
                userId: interests[i].user.id,
                tourId: interests[i].tour.id,
                interests: [...interests[i].fieldsOfPlay, ...interests[i].tradezones, ...interests[i].channels]
            };

            state.tourVisitorInterests = state.tourVisitorInterests.concat(visitorInterests);
        }
    },
    setTourInformation(state, information) {
        if (state.allUserTours.length) {
            const tourIndex = state.allUserTours.findIndex(tour => tour.id === information.id);

            state.allUserTours[tourIndex] = information;
        }

        if (state.availableTours.length) {
            const tourIndex = state.availableTours.findIndex(tour => tour.id === information.id);

            state.availableTours[tourIndex] = information;
        }
    },
    setAllModelTours(state, tours) {
        for (let i = 0; i < tours.length; i++) {
            tours[i].steps = tours[i].steps.sort(
                (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
            );
        }
        state.allModelTours = tours;
    },
    updateStep(state, information) {
        if (state.allUserTours.length) {
            const tourIndex = state.allUserTours.findIndex(tour => tour.id === information.tourId);
            const stepIndex = state.allUserTours[tourIndex].steps.findIndex(
                step => step.id === information.response.id
            );

            if (stepIndex !== -1) {
                const step = state.allUserTours[tourIndex].steps[stepIndex];

                step.duration = information.response.duration;
                step.startDate = information.response.startDate;

                state.allUserTours[tourIndex].steps.sort(
                    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                );
            }
        }

        if (state.availableTours.length) {
            const tourIndex = state.availableTours.findIndex(tour => tour.id === information.tourId);
            const stepIndex = state.availableTours[tourIndex].steps.findIndex(
                step => step.id === information.response.id
            );

            if (stepIndex !== -1) {
                const step = state.availableTours[tourIndex].steps[stepIndex];

                step.duration = information.response.duration;
                step.startDate = information.response.startDate;

                state.availableTours[tourIndex].steps.sort(
                    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                );
            }
        }
    },
    deleteStep(state, information) {
        if (state.allUserTours.length) {
            state.allUserTours.forEach(tour => {
                if(tour.id === information.tourId) {
                    tour.steps.filter(
                        step => step.id !== information.stepId
                    );
                }
            })
        }

        if (state.availableTours.length) {
            state.availableTours.forEach(tour => {
                if(tour.id === information.tourId) {
                    tour.steps.filter(
                        step => step.id !== information.stepId
                    );
                }
            })
        }
    },
    setTourThanks(state, thanks) {
        if (state.allUserTours.length) {
            const relevantTourIndex = state.allUserTours.findIndex(tour => tour.id === thanks.tour.id);

            state.allUserTours[relevantTourIndex].thanks = thanks;
            // @todo: strange behaviour
            // Vue.set(state.allUserTours, relevantTourIndex, state.allUserTours[relevantTourIndex]);
        }

        if (state.availableTours.length) {
            const relevantTourIndex = state.availableTours.findIndex(tour => tour.id === thanks.tour.id);

            state.availableTours[relevantTourIndex].thanks = thanks;
            // Vue.set(state.availableTours, relevantTourIndex, state.availableTours[relevantTourIndex]);
        }
    },
    setUserCurrentTour(state, tour) {
        tour[0].steps = tour[0].steps.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        state.userCurrentTour = tour;
    },
    setTourRating(state, rating) {
        const vote = rating.vote;

        vote.visitor.email = rating.email;
        vote.visitor.fullname = rating.name;
        delete vote.tour;

        if (state.userCurrentTour && state.userCurrentTour[0].id === rating.tourId) {
            state.userCurrentTour[0].rates.push(vote);
        }

        if (state.allUserTours.length) {
            const allTourIndex = state.allUserTours.findIndex(tour => tour.id === rating.tourId);

            if (allTourIndex !== -1) {
                state.allUserTours[allTourIndex].rates.push(vote);
            }
        }

        if (state.availableTours.length) {
            const allTourIndex = state.availableTours.findIndex(tour => tour.id === rating.tourId);

            if (allTourIndex !== -1) {
                state.availableTours[allTourIndex].rates.push(vote);
            }
        }
    },
    deleteHomepageTour(state, information) {
        const tourIndex = state.availableTours.findIndex(tour => tour.id === information.tourId);
        if (tourIndex !== -1) {
            state.availableTours.splice(tourIndex, 1);
        }
    },
    deleteTour(state, information) {
        const tourIndex = state.allUserTours.findIndex(tour => tour.id === information.tourId);
        if (tourIndex !== -1) {
            state.allUserTours.splice(tourIndex, 1);
        }
    },
    setHomepageTourStep(state, params) {
        params.step.id = params.stepId;
        const currentTour = state.availableTours.find(tour => Number(tour.id) === Number(params.searchId));
        if (currentTour) {
            currentTour.steps.push(params.step);
        }
    },
    setAllTourStep(state, params) {
        params.step.id = params.stepId;

        if (state.allUserTours.length) {
            const tourIndex = state.allUserTours.findIndex(tour => tour.id === params.searchId);
            state.allUserTours[tourIndex].steps.push(params.step);
        }
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

const actions = {
    async loadAllUserToursByCity({ rootState, commit, dispatch }, { userId, cityId, userPage = false, fromFeedbacks = false } = {}) {
        cityId = cityId || rootState.city.currentCity.id;
        userId = userId || rootState.user.userInfo.id;

        const apiPath = `users/${userId}/tours/all/${cityId}`;
        const params = !userPage ? { shortList: true, cache: false } : { cache: false };

        try {
            const result = await api.request(apiPath, params);

            result.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)); 
            result.forEach(tour => {
                tour.steps.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            });

			const actionName = fromFeedbacks || !userPage ? "setAvailableTours" : "setAllUserTours";

            commit(actionName, result);
			dispatch("feedback/loadFeedBackCount", result, { root: true });
            return result;
        } catch(error) {
            throw new Error(error)
        }
    },
    async loadAllModelToursByCity({ commit, state, rootState }, { cityId } = {}) {
        cityId = cityId || rootState.city.currentCity.id;

        commit("setAllModelTours", await api.request("tours", { "city.id": cityId, model: true }));
        return state.allModelTours;
    },
    async loadCurrentTour({ commit, state, rootState }, { userId } = {}) {
        userId = userId || rootState.user.userInfo.id;
        if (!state.userCurrentTour) {
            const tour = await api.request("users/" + userId + "/tour/current/" + rootState.city.currentCity.id, {});

            if (tour.length) {
                commit("setUserCurrentTour", tour);
            }
        }

        return state.userCurrentTour;
    },
    loadTourById({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.request(
                `tours/${params.id}`,
                { cache: false },
                tour => {
                    commit("setTour", tour);
                    resolve(tour);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    loadStepById({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            let step = getters.getStep(params.id);
            if (step) {
                resolve(step);
            } else {
                api.request(
                    "steps/" + params.id,
                    {},
                    step => {
                        commit("setStep", step);
                        resolve(step);
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    createTour({ commit }, { name, status, startDate, endDate, visitors, vip, city, published, visibleVisitors, isTourCreated }) {
        return new Promise((resolve, reject) => {
            api.post(
                "tours",
                {
                    name: name,
                    status: status,
                    startDate: startDate,
                    endDate: endDate,
                    visitors: visitors,
                    vip: vip,
                    city: "/api/cities/" + city,
                    published: published,
                    isTourCreated: isTourCreated
                },
                tour => {
                    for (let i = 0; i < tour.visitors.length; i++) {
                        for (let b = 0; b < visibleVisitors.length; b++) {
                            if (tour.visitors[i].id === visibleVisitors[b].id) {
                                tour.visitors[i].email = visibleVisitors[b].email;
                                tour.visitors[i].fullname = visibleVisitors[b].fullname;
                            }
                        }
                    }

                    commit("addUserGuideTour", tour);
                    commit("addHomepageGuideTour", tour);
                    resolve(tour);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    tourVisitorInterests({ commit, getters }, params) {
        return new Promise((resolve, reject) => {
            const visitorInterests = getters.getTourVisitorInterests(params.visitorIds, params.tourId);
            if (visitorInterests.length) {
                resolve(visitorInterests);
            } else {
                api.request(
                    "personal-interests",
                    {
                        "user.id": params.visitorIds,
                        "tour.id": params.tourId
                    },
                    visitorInterests => {
                        commit("addTourVisitorInterests", visitorInterests);
                        resolve(getters.getTourVisitorInterests(params.visitorIds, params.tourId));
                    },
                    error => {
                        reject(error);
                    }
                );
            }
        });
    },
    updateTour({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.put(
                "tours/" + params.tourId,
                {
                    name: params.name,
                    status: params.status,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    visitors: params.visitors,
                    city: "/api/cities/" + params.city,
                    vip: params.vip,
                    published: params.published,
                    updatedAt: params.updatedAt,
                    isTourCreated: params.isTourCreated ?? false
                },
                res => {
                    let tourData = res.data;

                    tourData.guide = params.tourGuide;

                    if(tourData.visitors?.length > 0 && params.visibleVisitors?.length) {
                        for (let i = 0; i < tourData.visitors.length; i++) {
                            for (let b = 0; b < params.visibleVisitors.length; b++) {
                                if (tourData.visitors[i].id === params.visibleVisitors[b].id) {
                                    tourData.visitors[i].email = params.visibleVisitors[b].email;
                                    tourData.visitors[i].fullname = params.visibleVisitors[b].fullname;
                                }
                            }
                        }
                    }

                    if(tourData.vip?.length > 0 && params.visibleVIPs?.length) {
                        for (let i = 0; i < tourData.vip.length; i++) {
                            for (let b = 0; b < params.visibleVIPs.length; b++) {
                                if (tourData.vip[i].id === params.visibleVIPs[b].id) {
                                    tourData.vip[i].email = params.visibleVIPs[b].email;
                                    tourData.vip[i].fullname = params.visibleVIPs[b].fullname;
                                }
                            }
                        }
                    }

                    commit("setTourInformation", tourData);
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    // eslint-disable-next-line
    createStep({ commit }, params) {
        return new Promise((resolve, reject) => {
            const APIParams = {
                startDate: params.startDate,
                duration: params.duration,
                tour: params.tour
            };

            if (params.inspirationalPlace) {
                APIParams.inspirationalPlace = params.inspirationalPlace;
            } else {
                APIParams.store = params.store;
            }

            api.post(
                "steps",
                { ...APIParams },
                step => {
                    commit("setHomepageTourStep", { searchId: params.searchId, step: params.step, stepId: step.id });
                    resolve(step);
                },
                error => {
                    reject(error);
                }
            );
        });
    },

    updateStep({ commit }, params) {
        return new Promise((resolve, reject) => {
            const APIParams = {
                startDate: params.startDate,
                duration: params.duration,
                tour: params.tour
            };

            if (params.inspirationalPlace) {
                APIParams.inspirationalPlace = params.inspirationalPlace;
            } else {
                APIParams.store = params.store;
            }

            api.put(
                "steps/" + params.stepId,
                { ...APIParams },
                res => {
                    commit("updateStep", { tourId: params.plainTourId, response: res.data });
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteStep({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.delete(
                "steps",
                {
                    id: params.stepId
                },
                res => {
                    commit("deleteStep", { tourId: params.tourId, stepId: params.stepId });
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    tourThanks({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.post(
                "thanks",
                {
                    content: params.content,
                    tour: params.tour,
                    images: params.images,
                    keyfacts: params.keyfacts
                },
                thanks => {
                    commit("setTourThanks", thanks);
                    resolve(thanks);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    tourVote({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.post(
                "rates",
                {
                    visitor: "api/users/" + params.visitor,
                    tour: "api/tours/" + params.tour,
                    note: params.note
                },
                vote => {
                    commit("setTourRating", {
                        vote,
                        name: params.visitorName,
                        email: params.visitorEmail,
                        tourId: params.tour
                    });
                    resolve(vote);
                },
                error => {
                    reject(error);
                }
            );
        });
    },
    deleteTour({ commit }, params) {
        return new Promise((resolve, reject) => {
            api.delete(
                "tours",
                {
                    id: params.tourId
                },
                res => {
                    commit("deleteTour", { tourId: params.tourId });
                    commit("deleteHomepageTour", { tourId: params.tourId });
                    resolve(res);
                },
                error => {
                    reject(error);
                }
            );
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
