const getDefaultState = () => {
    return {
        tourTitle: "",
        startDate: "",
        endDate: "",
        places: [],
        participants: [],
        error: null,
        info: null,
        steps: {
            createCityTour: true,
            programMyCityTour: false,
            completeCityTour: false,
            previewCreatedTour: false
        },
        showSearchPlaces: false,
        previewPlacesListView: false,
        showPreBuiltTour: false,
        programMyTour: true,
        inspirationalPlaces: [],
        stores: [],
        selectedPreBuiltTour: null,
        showSelectedPreBuiltTour: false,
        isPreBuiltTour: false,
        inputStartDateType: "",
        inputEndDateType: ""
    };
};

const state = getDefaultState();

const getters = {
    isValidFields(state) {
        return state.tourTitle && state.startDate && state.endDate && !(state.startDate > state.endDate);
    },
    getStores: state => state.stores
};

const mutations = {
    reset(state) {
        Object.assign(state, getDefaultState());
    },
    setTourTitle(state, payload) {
        state.tourTitle = payload;
    },
    setStartDate(state, payload) {
        state.startDate = payload;
    },
    setEndDate(state, payload) {
        state.endDate = payload;
    },
    setPlaces(state, payload) {
        state.places = payload;
    },
    setParticipants(state, payload) {
        state.participants = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setInfo(state, payload) {
        state.info = payload;
    },
    setSteps(state, payload) {
        state.steps = payload;
    },
    setShowSearchPlaces(state, payload) {
        state.showSearchPlaces = payload;
    },
    togglePreviewPlacesListView(state) {
        state.previewPlacesListView = !state.previewPlacesListView;
    },
    setShowPreBuiltTour(state, payload) {
        state.showPreBuiltTour = payload;
    },
    setProgramMyTour(state, payload) {
        state.programMyTour = payload;
    },
    setInspirationalPlaces(state, payload) {
        state.inspirationalPlaces = payload;
    },
    setStores(state, payload) {
        state.stores = payload;
    },
    setSelectedPreBuiltTour(state, payload) {
        state.selectedPreBuiltTour = payload;
    },
    setShowSelectedPreBuiltTour(state, payload) {
        state.showSelectedPreBuiltTour = payload;
    },
    setIsPreBuiltTour(state, payload) {
        state.isPreBuiltTour = payload;
    },
    setInputStartDateType(state, payload) {
        state.inputStartDateType = payload;
    },
    setInputEndDateType(state, payload) {
        state.inputEndDateType = payload;
    },

    nextStep(state, stepName) {
        for (const step in state.steps) {
            state.steps[step] = false;
            if (step === stepName) {
                state.steps[step] = true;
            }
        }
    },
    backToPragramTour(state) {
        state.showSearchPlaces = false;
        state.showPreBuiltTour = false;
        state.programMyTour = true;
    },
    backToPreBuiltTours(state) {
        state.showSelectedPreBuiltTour = false;
    },
    openSearchPlaces(state) {
        state.showSearchPlaces = true;
        state.showPreBuiltTour = false;
        state.programMyTour = false;
    },
    openPreBuiltTours(state) {
        state.showSearchPlaces = false;
        state.showPreBuiltTour = true;
        state.programMyTour = false;
    },
    completingSavingPreBuiltTour(state) {
        state.places = state.selectedPreBuiltTour.steps;
        state.selectedPreBuiltTour.steps.forEach(itm => {
            if (itm.inspirationalPlace) {
                itm.inspirationalPlace.ip = true;
            }
            this.commit("tourCreation/addPlaceToMap", itm.door || itm.inspirationalPlace);
        });
        state.isPreBuiltTour = true;
        this.commit("tourCreation/nextStep", "previewCreatedTour");
    },
    selectPreBuildTour(state, tour) {
        state.selectedPreBuiltTour = JSON.parse(JSON.stringify(tour));
        state.selectedPreBuiltTour.guide = this.state.user.userInfo;
        state.showSelectedPreBuiltTour = true;
    },

    addPlace(state, item) {
        const place = JSON.parse(JSON.stringify(item));

        if (state.places.find(oldItem => oldItem.id === item.id)) {
            return;
        }
        state.places.push(place);
        state.places = state.places.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        this.commit("tourCreation/addPlaceToMap", place);
        if (!state.steps.completeCityTour && !state.steps.previewCreatedTour) {
            this.commit("tourCreation/nextStep", "completeCityTour");
            this.commit("tourCreation/setShowSearchPlaces", false);
        }
    },
    addPlaceToMap(state, place) {
        if (!place) {
            return;
        }
        const placeObj = {
            fullAddress: place.address,
            id: place.id,
            images: place.images,
            latitude: place.latitude,
            longitude: place.longitude,
            name: place.name,
            duration: place.duration,
            isMustWinDoor: place.isMustWinDoor,
            startDate: place.startDate
        };
        if (!place.ip) {
            placeObj.tradezone = place.tradezone;
        }
        state.stores.push(placeObj);
    },
    removePlace(state, selectedItem) {
        state.places = state.places.filter(item => {
            if (item.store && item.store.id === selectedItem.id) {
                return false;
            } else if (item.inspirationalPlace && item.inspirationalPlace.id === selectedItem.id) {
                return false;
            } else if (item.id === selectedItem.id) {
                return false;
            }
            return true;
        });

        this.commit("tourCreation/removePlaceFromMap", selectedItem);
    },
    removePlaceFromMap(state, place) {
        if (place.type === "ip") {
            const iPIndex = state.inspirationalPlaces.findIndex(itm => itm.id === place.id);
            state.inspirationalPlaces.splice(iPIndex, 1);
        } else {
            const storeIndex = state.stores.findIndex(itm => itm.id === place.id);
            state.stores.splice(storeIndex, 1);
        }
    },
    updatePlace(state, editingPlace) {
        const currentPlace = state.places.find(place => place.id === editingPlace.id);

        if (currentPlace) {
            currentPlace.startDate = editingPlace.startDate;
            currentPlace.duration = editingPlace.duration;
        }
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {}
};
